.game-wrapper h1, .game-wrapper h3 {
  position: relative;
  z-index: 2;
}

.game-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.game-wrapper > * {
  position: absolute;
}