.app-wrapper {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 1200px;
}

h1, h2, h3 {
    display: block;
    margin-top: 30px;
    text-align: center;
    font-family: 'Rajdhani', sans-serif;
}