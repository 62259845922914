.EndScreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.end-text{
    font-family: 'Rajdhani', sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
}